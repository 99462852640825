#home {
    position: relative;

    // background: url("../../assets/bgIMG.png");
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
  
    // disable the default padding styles
    .app__wrapper {
      padding: 0;
  
      .copyright {
        display: none;
      }
    }
  }
  
  .app__header {
    flex: 0;
    width: 100%;
    height: 100%;
    flex-direction: row;
  
    padding: 5rem 0rem 0rem;
  
    @media screen and (min-width: 2000px) {
      padding-top: 8rem;
    }
  
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  
    @media screen and (max-width: 450px) {
      padding: 6rem 0rem 2rem;
    }
  }
  
  .app__header-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    // margin: 0 2rem;
  
  }

.app__header-badge{
    width: fit-content;
    display: flex;
    // align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    
    .badge-cmp,.tag-cmp{
        position: relative;
        background-color: var(--white-color);
         padding: 1rem 2rem;
         border-radius: 15px;
         flex-direction: row;
         width: auto;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }

    .tag-cmp{
        flex-direction: column;
        margin-top: 2rem;
        p{
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }
    
    span{
        font-size: 3rem;

        @media screen and (min-width:2000px) {
            font-size: 5rem; 
        }
    }

        @media screen and (max-width:1200px  ) {
            justify-content: flex-start;
            align-items: flex-start;
        }
  
}


.app__header-circles{
    height:100%;
    flex: 0.75;
    display: flex ;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-inline: 2rem;
    div{  
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: var(--white-color);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      position: relative;
        
        img{
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1) {
        width: 100px;
        height: 100px;
      }
    
      div:nth-child(2) {
        margin: 1.75rem;
        width: 150px;
        height: 150px;
      }
    
      div:nth-child(3) {
        width: 70px;
        height: 70px;
      }

    @media screen and (min-width:2000px) {
        div:nth-child(2) {
            width: 400px;
            height: 400px;
          }
      
          div:nth-child(3) {
            width: 170px;
            height: 170px;
          }
      
          div:nth-child(1) {
            width: 200px;
            height: 200px;
          }
    }

    @media screen and (max-width:1100px) {
        flex-direction: row;
        flex-wrap: wrap;
        margin-block: 1.7rem;
        // margin-left: -1rem;

    }

}

.app__header-img{
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        width: 100%;
        object-fit: contain;
        z-index: 1;
      }

    .overlay-circle{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 90%;
        z-index: 0;
    }

    @media screen and (max-width:900px) {
        margin: 2.1rem 0;
    }

}

