@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
   --font-base: "DM Sans", sans-serif;
   --primary-color: #edf2f8;
   --secondary-color: #313bac;
   --black-color: #030303;
   --lightGray-color: #e4e4e4;
   --gray-color: #6b7688;
   --brown-color: #46364a;
   --white-color: #ffffff;
}



* {
   box-sizing: border-box;
   padding: 0;
   margin: 0;
   scroll-behavior: smooth;
}


.exampleBody {
   width: 80vw;
   margin: auto;
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: space-evenly;
   /* background-color: red; */
 }
 
 .exampleContainer {
   width: 50%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   padding: 5px;
 }
 
 .example {
   width: 80%;
   /* background-color: #0022FF; */
   /* stroke: #B937ff; */
   stroke:#313bac;
   padding: 4%;
   border: none;
   display: flex;
   justify-content: center;
   align-items: center;
   stroke-dashoffset: 822;
 
 }