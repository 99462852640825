
.app__navbar{
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border: 1px solid rgba(255,255,255,0.2);
    background-color: rgba(255,255,255,0.28);
    position: fixed;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);

}

.app__navbar-logo{
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        width: 100px;
        padding: 0.3rem;
        cursor: pointer;
    }

    @media screen and (min-width:2000px) {
            width: 180px;
    }
}

.app__navbar-links{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
    li{
        margin: 0 1rem;
        cursor: pointer;
        flex-direction: column;
        margin-bottom: 10px;

        &:hover{
            div{
                background-color: var(--secondary-color);
            }
        }

        
        
    }

    div{
        width: 6px;
        height: 6px;
        border-radius:50% ;
        background-color: transparent;
        margin-bottom: 4px;
    }

    a{
        color: var(--grey-color);
        text-decoration: none;
        flex-direction: column;
        text-transform: uppercase;
        font-weight: 500;
        transition: 0.3s all ease-in-out;

        &:hover{
        color: var(--secondary-color);
        }
        
        
    }

    @media screen and (max-width:900px) {
        display: none;
    }
}


.app__navbar-menu{
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    svg{
        width: 60%;
        height: 60%;
        color: white;
    }

    // Framer Motion Div
   div{
    width: 80%;
    height: 100vh;
    top: 0;
    right: 00px;
    bottom: 0;
    position: fixed;
    z-index: 5;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    background: url("../../assets/bgWhite.png");
    background-color: white;
    background-size: cover;
    background-repeat: repeat;
  
    svg{
        
        cursor: pointer;
        width: 45px;
        height: 45px;
        color: var(--secondary-color);
        margin: 1rem 1rem;
    }

    ul{
        list-style: none;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 2rem;

        li{
                div{
                    display: none;
                }
        }
        
        a{
            font-size: 1.2rem;
            color: var(--grey-color);
            text-decoration: none;
            transition: 0.3s all ease-in-out;

            &:hover{
            color: var(--secondary-color);
            }
        }
    }


    @media screen and (min-width:900px){
        display: none;
    }

    }


    @media screen and (min-width:900px) {
            display: none;
    }

   
}

